import { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import { selectShowNav, selectUser } from '../../redux/systemSlice'
import { selectDocumentState, setContent, setIsOnSubtopicsStep } from '../../redux/docSlice'
import { useAuth } from '../../contexts/Auth'
import { CONSTANTS, DOC_TEMPLATES } from '../../constants'
import './_AddMore.scss'
import { htmlToString } from '../../helpers/utility'
import { DocumentState } from '../../redux/types'
import ReplayIcon from '@mui/icons-material/Replay'
import AddIcon from '@mui/icons-material/Add'
import { useCookieUser } from '../../hooks/cookieUser'
import Revert from '../Modals/Revert'
import { useAtom } from 'jotai'
import { accountModalAtom } from '../../Pages/Create/Create'
import WordCount from '../Navbar/WordCount'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import useIsPremium from '../../hooks/useIsPremium'
import AddMenu from '../Document/AddMenu'
import { Editor } from '@tiptap/core'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import { LuCirclePlus } from 'react-icons/lu'

type AddMoreProps = {
	type: string | undefined
	docID: string
	isFixed: boolean
	editor: Editor
}

function AddMore({ type, docID, isFixed, editor }: AddMoreProps) {
	const { userIDCookie } = useCookieUser()
	const [accountModalState, setAccountModalState] = useAtom(accountModalAtom)
	const [addMoresliderValue, setAddMoresliderValue] = useState(CONSTANTS.ADD_MORE_MIN)
	const closeAddMore = useRef(null)
	const user = useSelector(selectUser)
	const dispatch = useDispatch()
	const showNav = useSelector(selectShowNav)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const { currentUser } = useAuth() as any
	const [addMoreOpen, setAddMoreOpen] = useState(false)
	const [revertModalOpen, setRevertModalOpen] = useState(false)
	const [isTypingAddMore, setIsTypingAddMore] = useState(false)
	const posthog = usePostHog()
	const isPremium = useIsPremium()
	const alwaysShowNavFF = useFeatureFlagVariantKey(FEATURE_FLAGS.ALWAYS_SHOW_NAV)
	const bottomNavWordCountFF = useFeatureFlagVariantKey(FEATURE_FLAGS.BOTTOM_NAV_WORD_COUNT)
	const bottomNavButtonRedesignFF = useFeatureFlagVariantKey(FEATURE_FLAGS.BOTTOM_NAV_BUTTON_REDESIGN)

	async function generateAddMore() {
		posthog.capture('add-more-generated')
		setIsTypingAddMore(true)
		const token = currentUser ? await currentUser.getIdToken() : ''

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				prompt: htmlToString(documentState.content.split(' ').slice(-100).join(' ')),
				userId: user.id ?? userIDCookie,
				docId: docID,
				wordCount: addMoresliderValue,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/add-more/', requestOptions)
			.then((res) => res.json())
			.then((response: { addMore: string }) => {
				if (response.addMore) {
					dispatch(setContent({ docID: docID, content: documentState.content + response.addMore }))
					setIsTypingAddMore(false)
				}
			})
	}

	async function getAddMore() {
		if (!isPremium) {
			setAccountModalState({ ...accountModalState, subscriptionModalOpen: true })
			return
		}

		const token = currentUser ? await currentUser.getIdToken(true) : undefined
		if (!token) {
			setAccountModalState({ ...accountModalState, loginModalOpen: true })
			return
		}
		generateAddMore()
	}

	const addMoreContent = <AddMenu editor={editor} appendAtEnd />

	return (
		<>
			<Revert
				docID={docID}
				closeModal={() => {
					setRevertModalOpen(false)
				}}
				isOpen={revertModalOpen}
			/>
			<div
				className={`flex addMoreContainer mt-6  ${isFixed ? 'fixed' : 'sticky'} ${
					showNav || alwaysShowNavFF === 'test' ? 'visible ' : ''
				}`}
			>
				<div className="flex gap-4 justify-between w-full items-center">
					<div className={`flex items-center ${bottomNavButtonRedesignFF === 'test' ? 'gap-4' : 'gap-1'}`}>
						{documentState.content && !documentState.isGenerating && !documentState.isTypingEssay && (
							<>
								<span
									className={`${
										bottomNavButtonRedesignFF === 'test'
											? 'p-1 hover:bg-gray-100 rounded-full w-8 h-8 relative bottom-0.5'
											: 'addMore'
									} ${addMoreOpen ? ' open ' : ''}`}
									onClick={() => {
										!documentState.isTypingEssay && !isTypingAddMore && setAddMoreOpen(!addMoreOpen)
										posthog.capture('add-more-clicked')
									}}
									ref={closeAddMore}
								>
									{bottomNavButtonRedesignFF === 'test' ? LuCirclePlus({ size: 25 }) : <AddIcon />}
								</span>
							</>
						)}

						{!documentState.isGenerating &&
							!documentState.isTypingEssay &&
							!isTypingAddMore &&
							type !== DOC_TEMPLATES.Blank && (
								<>
									<span
										className={
											bottomNavButtonRedesignFF === 'test'
												? 'p-1 hover:bg-gray-100 rounded-full w-8 h-8 flex justify-center items-center'
												: 'revert'
										}
										onClick={() => {
											setRevertModalOpen(true)
											dispatch(setIsOnSubtopicsStep({ docID: docID, isOnSubtopicsStep: false }))
										}}
									>
										<ReplayIcon />
									</span>
								</>
							)}
					</div>
					<div className={`flex gap-4 ${bottomNavWordCountFF === 'test' ? '' : 'sm:hidden'}`}>
						<WordCount />
					</div>
				</div>

				{/* {documentState?.content && !documentState.isGenerating && documentState.isTypingAddMore && (
				<Basic
					onClick={() => {
						if (addMoreReader) addMoreReader.cancel()
					}}
					type="tertiary"
					CTA={<span>Stop autocomplete</span>}
					className="abortButton"
				/>
			)} */}
				<Dropdown
					open={addMoreOpen}
					setOpen={(val) => {
						setAddMoreOpen(val)
					}}
					body={addMoreContent}
					closeButton={closeAddMore}
					className={`addMoreDropdown absolute md:top-[-245px] top-[-300px] left-0 bg-white`}
				/>
			</div>
		</>
	)
}

export default AddMore
