import { useEffect, useState, useMemo } from 'react'
import { Link, RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import Button from '../Buttons'
import { CONSTANTS, DOC_TEMPLATES } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowNav, setShowNav } from '../../redux/systemSlice'
import { selectDocumentState } from '../../redux/docSlice'
import { useAuth } from '../../contexts/Auth'
import { useIsAuthenticated } from '../../hooks/authenticated'
import { htmlToString } from '../../helpers/utility'
import { formatReferenceAsString } from '../../helpers/strings'
import { DocumentState } from '../../redux/types'
import Detector from './Detector'
import { useDocId } from '../../hooks/docID'
import useEnterApp from '../../hooks/enterApp'
import AccountModal from '../Modals/Account'
import Subscriptions from '../Modals/Subscriptions'
import WordCount from './WordCount'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import LoginModal from '../Modals/Login'
import Grader from './Grader'
import useIsPremium from '../../hooks/useIsPremium'
import ToolCredits from '../ToolCredits'
import SuggestMonthly from '../Modals/SuggestMonthly'
import { FEATURE_FLAGS, useAnnualPlan } from '../../hooks/featureFlags'
import CopyButton from '../Copy'
import { FaArrowLeft } from 'react-icons/fa6'
import { FaPlus } from 'react-icons/fa6'
import { useAtom } from 'jotai'
import { docLoadingAtom } from '../../Pages/Create/Create'

const baseClassNames = 'flex justify-between items-center mx-auto md:h-auto px-4 py-2 md:py-3 md:px-auto'

function Navbar(props: RouteComponentProps) {
	const [createUserLoading, setCreateUserLoading] = useState(false)
	const [copied, setCopied] = useState(false)
	const [accountModalOpen, setAccountModalOpen] = useState(false)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const [suggestMonthlyModalOpen, setSuggestMonthlyModalOpen] = useState(false)
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
	const [annualPlan, setAnnualPlan] = useAnnualPlan()
	const monthlySuggestionFF = useFeatureFlagVariantKey(FEATURE_FLAGS.MONTHLY_SUGGESTION)
	const newEssayButtonFF = useFeatureFlagVariantKey(FEATURE_FLAGS.NEW_ESSAY_BUTTON)
	const alwaysShowNavFF = useFeatureFlagVariantKey(FEATURE_FLAGS.ALWAYS_SHOW_NAV)
	const documentViewV2 = useFeatureFlagVariantKey(FEATURE_FLAGS.DOCUMENT_VIEW_V2)
	const bottomNavWordCountFF = useFeatureFlagVariantKey(FEATURE_FLAGS.BOTTOM_NAV_WORD_COUNT)
	const searchParamsString = document.location.search
	const dispatch = useDispatch()
	const showNavRedux = useSelector(selectShowNav)
	const docID = useDocId()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const pathname = window.location.pathname
	const posthog = usePostHog()
	const { currentUser } = useAuth() as any
	const isAuthenticated = useIsAuthenticated()
	const { enterApp } = useEnterApp({ history: props.history, setLoading: setCreateUserLoading })
	const [isPremiumPath, setIsPremiumPath] = useState(false)
	const isPremium = useIsPremium()
	const history = useHistory()
	const showNav = showNavRedux || alwaysShowNavFF === 'test'
	const [docLoading] = useAtom(docLoadingAtom)

	const logo = <span className="text-4xl md:text-5xl leading-0 w-0 relative right-1">○</span>

	const searchParams = useMemo(() => {
		let params = new URLSearchParams(searchParamsString)
		return {
			canceled: params.getAll('canceled')[0],
		}
	}, [searchParamsString])

	useEffect(() => {
		if (searchParams.canceled) {
			posthog?.capture('checkout-canceled', { annual: annualPlan })
			if (annualPlan && monthlySuggestionFF === 'test' && !subscriptionModalOpen) {
				setSuggestMonthlyModalOpen(true)
				setAnnualPlan(false)
			} else if (!suggestMonthlyModalOpen && !!monthlySuggestionFF) {
				setSubscriptionModalOpen(true)
			}
		}
	}, [searchParams.canceled, monthlySuggestionFF, annualPlan])

	//Control showing the nav bar for user mouse move and scroll
	useEffect(() => {
		const onMouseMove = () => {
			dispatch(setShowNav({ value: true }))
		}
		const onScroll = () => {
			const documentPathname = document.location.pathname
			if ([CONSTANTS.CREATE_PATH, CONSTANTS.DOC_PATH].includes(documentPathname)) {
				return
			}
			dispatch(setShowNav({ value: true }))
		}

		window.addEventListener('scroll', onScroll)
		window.addEventListener('mousemove', onMouseMove)
		return () => {
			window.removeEventListener('mousemove', onMouseMove)
			window.removeEventListener('scroll', onScroll)
		}
	})

	useEffect(() => {
		if (copied) {
			const timeoutId = setTimeout(() => {
				setCopied(false)
			}, 2000)
			return () => clearTimeout(timeoutId)
		}
	}, [copied])

	const nameAndLogo = (
		<Link to={'/'} className="flex items-center md:gap-2 gap-0">
			<span className="logo">{logo} </span>
			<span className="lowercase text-primary text-lg md:text-[26px] font-semibold">
				<span>
					The Good <span className="text-gray-500 fancy-font font-normal">AI</span>
				</span>
			</span>
		</Link>
	)

	const copyButton = useMemo(() => {
		if (!documentState) return

		return (
			<div className="md:mr-7 mr-8 bottom-0.5 relative h-6">
				<CopyButton
					textToCopy={
						htmlToString(documentState.content ?? '') +
						(documentState.references?.length > 0
							? '\n\nReferences\n' +
							  documentState.references
									.map((ref, index) => formatReferenceAsString(documentState.userWriterSelections.refStyle!, ref))
									.join('\n')
							: '')
					}
					disabled={
						documentState.isGenerating ||
						documentState.isTypingAddMore ||
						documentState.isTypingEssay ||
						documentState.content === CONSTANTS.EMPTY_DOCUMENT ||
						documentState.content === ''
					}
					onCopy={() => posthog?.capture('copy-button-clicked')}
				/>
			</div>
		)
	}, [copied, documentState, posthog])

	const basicNavbar = {
		desktop: (
			<>
				<div
					id="desktopNavbar"
					className={`${baseClassNames} px-4 lg:px-56 max-w-[1800px] basic ` + (showNav ? 'visible' : '')}
				>
					{nameAndLogo}
					<span onClick={() => enterApp()}>
						<Button type="tertiary">
							<span>{createUserLoading ? 'Loading...' : isAuthenticated ? 'Launch App' : 'Use for Free'}</span>
						</Button>
					</span>
				</div>
			</>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} basic ` + (showNav ? 'visible' : '')}>
				{nameAndLogo}
				<span onClick={() => enterApp()}>
					<Button type="tertiary">
						<span>{createUserLoading ? 'Loading...' : isAuthenticated ? 'Launch App' : 'Use for Free'}</span>
					</Button>
				</span>
			</div>
		),
	}

	const docNavbar = {
		desktop: (
			<div id="desktopNavbar" className={`${baseClassNames} doc ` + (showNav ? 'visible' : '')}>
				<Link to={'/'}>
					<span className="logo">{logo} </span>
				</Link>
				<div className="flex items-center gap-2">
					{currentUser ? (
						<Button type="tertiary" onClick={() => setAccountModalOpen(true)}>
							<span>ACCOUNT</span>
						</Button>
					) : (
						<Button type="tertiary" onClick={() => setLoginModalOpen(true)}>
							<span>LOG IN</span>
						</Button>
					)}
					{/* {!currentUser && (
						<Button type="tertiary" onClick={docNavbarCTAFunc}>
							<span>SIGN UP</span>
						</Button>
					)} */}
				</div>
			</div>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} doc ` + (showNav ? 'visible' : '')}>
				<div className="flex justify-between w-full items-center">
					<Link to={'/'}>
						<span className="logo">{logo}</span>
					</Link>
					<div className="flex items-center gap-2">
						{currentUser ? (
							<Button type="tertiary" onClick={() => setAccountModalOpen(true)}>
								<span>ACCOUNT</span>
							</Button>
						) : (
							<Button type="tertiary" onClick={() => setLoginModalOpen(true)}>
								<span>LOG IN</span>
							</Button>
						)}
						{/* {!currentUser && (
							<Button type="tertiary" className="hover:bg-secondary" onClick={docNavbarCTAFunc}>
								<span>SIGN UP</span>
							</Button>
						)} */}
					</div>
				</div>
			</div>
		),
	}

	const newDocNavbar = {
		desktop: (
			<div id="desktopNavbar" className={`${baseClassNames} doc ` + (showNav ? 'visible' : '')}>
				<Link to={'/'}>
					<span className="logo">{logo} </span>
				</Link>
			</div>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} doc ` + (showNav ? 'visible' : '')}>
				<div className="flex justify-between w-full items-center">
					<Link to={'/'}>
						<span className="logo">{logo}</span>
					</Link>
				</div>
			</div>
		),
	}

	const upgradeButton = (
		<Button
			type="outline"
			onClick={() => {
				setSubscriptionModalOpen(true)
				posthog?.capture('nav-upgrade-button-clicked')
				setIsPremiumPath(true)
			}}
			className={`md:px-5 px-2 !py-1 !font-normal`}
		>
			Upgrade
		</Button>
	)

	const createNavbar = {
		desktop:
			documentState?.setup && documentViewV2 === 'test' ? (
				<div
					id="desktopNavbar"
					className={
						`${baseClassNames} group transition-opacity duration-500 shadow xl:!px-0 ` +
						(showNav && !docLoading ? 'opacity-100' : 'opacity-0')
					}
				>
					<div className="max-w-[1200px] mx-auto flex justify-between items-center w-full">
						<div className="flex items-center gap-3">
							<Link to={CONSTANTS.DOC_PATH} className="relative transform hover:-translate-x-1 transition-transform">
								{FaArrowLeft({ className: 'text-xl' })}
							</Link>
							{newEssayButtonFF === 'test' && <div className="h-5 border-r-2 border-gray-300 ml-4" />}
							{documentState && documentState.setup && newEssayButtonFF === 'test' && !documentState.isGenerating && (
								<Button
									type="outline"
									onClick={() => {
										history.push('/new/' + DOC_TEMPLATES.Writer)
									}}
									className="!text-base whitespace-nowrap px-3 !py-0.5 !font-normal"
								>
									{FaPlus({ className: 'mr-2 !text-base relative -top-0.5 inline-block', size: 14 })}
									New Essay
								</Button>
							)}
						</div>
						{documentState && documentState.setup && !documentState.isGenerating && (
							<>
								<div className="desktopNavRight flex items-center">
									<span className="text-gray-600 mr-8 opacity-0 group-hover:opacity-100 transition-opacity">
										Saves automatically
									</span>
									{!documentState.isGenerating && bottomNavWordCountFF !== 'test' && <WordCount />}
									{copyButton}
									<div className="mr-4">
										<Grader />
									</div>
									{isPremium && <Detector />}
									{!isPremium && upgradeButton}
								</div>
							</>
						)}
					</div>
				</div>
			) : (
				<div
					id="desktopNavbar"
					className={
						`${baseClassNames} group transition-opacity duration-500 create ` + (showNav ? 'opacity-100' : 'opacity-0')
					}
				>
					<div className="flex items-center gap-8">
						<Link to={CONSTANTS.DOC_PATH} className="relative transform hover:-translate-x-1 transition-transform">
							{FaArrowLeft({ className: 'text-3xl' })}
						</Link>
						{documentState && documentState.setup && newEssayButtonFF === 'test' && !documentState.isGenerating && (
							<Button
								type="outline"
								onClick={() => {
									history.push('/new/' + DOC_TEMPLATES.Writer)
								}}
								className="!text-base whitespace-nowrap px-3 !py-1 !font-normal"
							>
								{FaPlus({ className: 'mr-1.5 !text-base relative -top-0.5 inline-block' })}
								New Essay
							</Button>
						)}
					</div>
					{documentState && documentState.setup && !documentState.isGenerating && (
						<>
							<div className="desktopNavRight flex items-center">
								<span className="text-gray-600 mr-8 opacity-0 group-hover:opacity-100 transition-opacity">
									Saves automatically
								</span>
								{!documentState.isGenerating && <WordCount />}
								{copyButton}
								<div className="mr-4">
									<Grader />
								</div>
								{isPremium && <Detector />}
								{!isPremium && upgradeButton}
							</div>
						</>
					)}
				</div>
			),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} create `}>
				<div className="flex justify-between w-full items-center">
					<Link to={CONSTANTS.DOC_PATH} className="relative transform hover:-translate-x-1 transition-transform">
						{FaArrowLeft({ className: 'text-2xl' })}
					</Link>
					{documentState?.setup && isPremium && !documentState?.isGenerating && (
						<div className="flex md:gap-3 gap-1">
							{isPremium && copyButton}
							<Grader />
							{isPremium && <Detector />}
						</div>
					)}
					{!isPremium && documentState && documentState.setup && !documentState.isGenerating && (
						<div className="flex gap-2">
							{copyButton}
							<Grader />
							{upgradeButton}
						</div>
					)}
				</div>
			</div>
		),
	}

	const toolNavbar = {
		desktop: (
			<div
				id="desktopNavbar"
				className={
					`${baseClassNames} group transition-opacity duration-500 create ` + (showNav ? 'opacity-100' : 'opacity-0')
				}
			>
				<Link to={CONSTANTS.DOC_PATH} className="relative transform hover:-translate-x-1 transition-transform">
					{FaArrowLeft({ className: 'text-3xl' })}
				</Link>
				{documentState && documentState.setup && !documentState.isGenerating && (
					<>
						<div className="desktopNavRight flex items-center gap-3">
							<ToolCredits />
							{!isPremium && upgradeButton}
						</div>
					</>
				)}
			</div>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} create `}>
				<div className="flex justify-between w-full items-center">
					<Link to={CONSTANTS.DOC_PATH} className="relative transform hover:-translate-x-1 transition-transform">
						{FaArrowLeft({ className: 'text-3xl' })}
					</Link>
					{!isPremium && documentState && documentState.setup && !documentState.isGenerating && upgradeButton}
				</div>
			</div>
		),
	}

	const navigation = [
		{ path: '/examples', nav: basicNavbar },
		{ path: '/examples/', nav: basicNavbar },
		{ path: CONSTANTS.DOC_PATH, nav: docNavbar },
		{ path: CONSTANTS.CREATE_PATH, nav: createNavbar },
		{ path: '/humanizer', nav: toolNavbar },
		{ path: '/detector', nav: toolNavbar },
		{ path: '/new', nav: newDocNavbar },
		{ path: '/', nav: basicNavbar },
	]

	let navBar = basicNavbar

	for (let i = 0; i < navigation.length; i++) {
		if (pathname.includes(navigation[i].path)) {
			navBar = navigation[i].nav
			break
		}
	}

	return (
		<>
			<Subscriptions
				open={subscriptionModalOpen}
				closeModal={() => {
					setSubscriptionModalOpen(false)
				}}
				setLoginModal={(open: boolean) => {
					setLoginModalOpen(open)
					if (open) {
						setIsPremiumPath(true)
					}
				}}
				annualPlan={annualPlan}
				setAnnualPlan={setAnnualPlan}
			/>
			<AccountModal isOpen={accountModalOpen} closeModal={() => setAccountModalOpen(false)} />
			<LoginModal
				open={loginModalOpen}
				setModalOpen={setLoginModalOpen}
				premiumPath={isPremiumPath}
				annualPlan={annualPlan}
			/>
			<SuggestMonthly
				open={suggestMonthlyModalOpen}
				closeModal={() => {
					setSuggestMonthlyModalOpen(false)
				}}
			/>
			<div className="bg-white w-full fixed top-0 z-20">
				<div className="block md:hidden">{navBar.mobile}</div>
				<div className="hidden md:block">{navBar.desktop}</div>
			</div>
		</>
	)
}

export default withRouter(Navbar)
