import { useAtom } from 'jotai'
import * as Sentry from '@sentry/react'
import { useMemo } from 'react'
import { editorAtom } from './constants'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import useHumanizerCredits from '../../hooks/useHumanizerCredits'
import { humanizedTextResultToDrafts } from './helpers'

export const useWordCount = (text: string) => {
	return useMemo(() => {
		const trimmedValue = text.trim().replace(/\s+/g, ' ')
		return trimmedValue.length === 0 ? 0 : trimmedValue.split(' ').length
	}, [text])
}

export const useHumanizeText = ({ onSuccess }: { onSuccess?: () => void }) => {
	const humanizerV2 = useFeatureFlagVariantKey(FEATURE_FLAGS.HUMANIZER_V4)
	const humanizeSentencesFF = useFeatureFlagVariantKey(FEATURE_FLAGS.SENTENCE_HUMANIZER)
	const [editorState, setEditorState] = useAtom(editorAtom)
	const wordCount = useWordCount(editorState.textInputValue)
	const { consumeCredits } = useHumanizerCredits()

	async function makeHumanizerRequest(textToHumanize: string) {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				text: textToHumanize,
				humanizerIntensity: editorState.humanizerIntensityLevel,
				purpose: editorState.purpose,
				literacyLevel: editorState.literacyLevel,
				plainText: true,
				useHumanizerV2: humanizerV2 === 'test' ? true : false,
			}),
		}
		try {
			// Await the fetch call and its conversion to JSON
			const response = await fetch(
				process.env.REACT_APP_API_ROOT +
					(humanizeSentencesFF === 'test' ? '/essay/humanize-sentence-free/' : '/essay/humanize/'),
				requestOptions
			)
			const result = await response.json()
			return result
		} catch (e) {
			Sentry.captureException(e)
			// Ensure that in case of error, the function returns a fallback or error indicator
			return '' // Consider returning null or a specific error object depending on your handling logic
		}
	}

	const humanize = async (retry: boolean) => {
		setEditorState({
			...editorState,
			isLoadingHumanizeText: true,
			humanizedText: undefined,
		})

		let textToHumanize = editorState.previousHumanization
			? editorState.previousHumanization.text
			: editorState.textInputValue

		const humanizedTextResult = await makeHumanizerRequest(textToHumanize)
		setEditorState((editorState) => ({
			...editorState,
			isLoadingHumanizeText: false,
		}))
		if (humanizeSentencesFF === 'test') {
			let humanizedDrafts = humanizedTextResultToDrafts(humanizedTextResult)

			setEditorState((editorState) => ({
				...editorState,
				humanizedDrafts: humanizedDrafts,
			}))
		} else {
			setEditorState((editorState) => ({
				...editorState,
				humanizedText: humanizedTextResult.content,
			}))
		}
		onSuccess?.()

		if (!retry) {
			consumeCredits(wordCount)
		}

		setTimeout(
			() =>
				setEditorState((editorState) => ({
					...editorState,
					previousHumanization: {
						text: textToHumanize,
						intensityLevel: editorState.humanizerIntensityLevel,
					},
				})),
			500
		)
	}

	return humanize
}
