import Button from '../Buttons'
import { selectGraderModeOn, selectUser, setGraderModeOn, setNumAcceptedSuggestions } from '../../redux/systemSlice'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { DocumentState } from '../../redux/types'
import { selectDocumentState } from '../../redux/docSlice'
import { htmlToString, useLocalStorage } from '../../helpers/utility'
import { useDocId } from '../../hooks/docID'
import { useAuth } from '../../contexts/Auth'
import { useEffect, useState } from 'react'
import { CONSTANTS } from '../../constants'
import RubricModal from '../Modals/Rubric'
import { TbReportAnalytics } from 'react-icons/tb'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import Toggle from '../Toggle'
import useDocWordCount from '../../hooks/useDocWordCount'
import useIsPremium from '../../hooks/useIsPremium'
import ReviewerSelectionModal from '../Modals/ReviewerSelection'
import HumanReviewerModal from '../Modals/HumanReviewer'

export type GraderRubric = {
	constructiveComments: string
	thesisStatement: string
	weakWords: string
	relevanceToTopic: number
	depthOfAnalysis: number
	evidenceAndSupport: number
	consistencyOfTone: number
	correctGrammar: number
	correctSpelling: number
	goodWordChoice: number
	qualityOfParagraphTransitions: number
}

const Grader = () => {
	const [graderModalOpened, setGraderModalOpened] = useLocalStorage('graderModalOpened', false)
	const [reviewerType, setReviewerType] = useState<'ai' | 'human' | null>(null)
	const [modalOpen, setModalOpen] = useState(false)
	const [scoreLoading, setScoreLoading] = useState(false)
	const [gradeResult, setGradeResult] = useState<GraderRubric | null>(null)
	const docID = useDocId()
	const { currentUser } = useAuth() as any
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const user = useSelector(selectUser)
	const posthog = usePostHog()
	const graderV2 = useFeatureFlagVariantKey(FEATURE_FLAGS.GRADER_V2)
	const humanReviewerFF = useFeatureFlagVariantKey(FEATURE_FLAGS.EXPERT_REVIEWER)
	const graderModeOn = useSelector(selectGraderModeOn)
	const wordCount = useDocWordCount()
	const dispatch = useDispatch()
	const isPremium = useIsPremium()

	const handleGrade = async () => {
		posthog.capture('grade-essay')
		setScoreLoading(true)
		setModalOpen(true)

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				topic: htmlToString(documentState.title),
				essay: htmlToString(documentState.content),
				userId: user.id,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/grade/', requestOptions)
			.then((res) => res.json())
			.then((response: GraderRubric) => {
				setGradeResult(response)
				setScoreLoading(false)
			})
			.catch((err) => {
				Sentry.captureException(err)
			})
	}

	useEffect(() => {
		if (reviewerType === 'ai') {
			handleGrade()
		}
	}, [reviewerType])

	if (graderV2 === 'test') {
		return (
			<div className="flex gap-2 items-center cursor-pointer" onClick={() => dispatch(setGraderModeOn(!graderModeOn))}>
				<div className="text-sm">Grader Mode</div>
				<Toggle
					isToggled={graderModeOn}
					onToggle={() => {
						dispatch(setNumAcceptedSuggestions(0))
					}}
				/>
			</div>
		)
	}

	return (
		<>
			<RubricModal
				isOpen={humanReviewerFF === 'test' ? modalOpen && reviewerType === 'ai' : modalOpen}
				onClose={() => {
					setModalOpen(false)
					setReviewerType(null)
				}}
				loading={scoreLoading}
				rubric={gradeResult}
			/>
			<HumanReviewerModal
				open={humanReviewerFF === 'test' ? modalOpen && reviewerType === 'human' : false}
				onClose={() => {
					setModalOpen(false)
					setReviewerType(null)
				}}
			/>
			<ReviewerSelectionModal
				open={humanReviewerFF === 'test' ? modalOpen && reviewerType === null : false}
				onClose={() => {
					setModalOpen(false)
					setReviewerType(null)
				}}
				onReviewerSelect={setReviewerType}
			/>
			<div>
				<Button
					type="outline"
					disabled={
						documentState &&
						(documentState.isHumanizing ||
							documentState.isGenerating ||
							documentState.isTypingAddMore ||
							documentState.isTypingEssay ||
							documentState.content === '' ||
							documentState.content === CONSTANTS.EMPTY_DOCUMENT ||
							wordCount < 20)
					}
					onClick={() => {
						posthog.capture('grade-essay-button-clicked')
						if (isPremium && humanReviewerFF === 'test') {
							setGraderModalOpened(true)
							setModalOpen(true)
							return
						}
						handleGrade()
					}}
					className={`px-2 md:px-3 !py-1 !font-normal`}
				>
					<div className="flex gap-2 items-center">
						{!graderModalOpened && isPremium && humanReviewerFF === 'test' && (
							<div className="w-2 h-2 bg-[#d62424] rounded"></div>
						)}
						{TbReportAnalytics({ className: 'hidden md:block' })}
						<div>{isPremium && humanReviewerFF === 'test' ? 'Review' : 'Grade'}</div>
					</div>
				</Button>
			</div>
		</>
	)
}

export default Grader
