import { useEffect, useMemo } from 'react'
import './_WriterOptions.scss'
import { useDispatch, useSelector } from 'react-redux'
import { CONSTANTS, TYPE_OPTIONS, HUMANIZER_OPTIONS, DOC_TEMPLATES } from '../../constants'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import Button from '../Buttons'
import { isEqual } from 'lodash'
import { DocumentState, UserWriterSelections } from '../../redux/types'
import { blankUserWriterSelections } from '../../redux/constants'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS, useFreeWordLimit } from '../../hooks/featureFlags'
import WritingStyleSelection from './WritingStyle'
import ReferencesSelection from './References'
import useIsPremium from '../../hooks/useIsPremium'

type Props = {
	type: string
	premiumUser: boolean
	formValid: boolean
	localUserWriterSelections: UserWriterSelections
	docID: string
	setSubscriptionModalOpen: (open: boolean) => void
	topicValue: string
}

function WriterOptions(props: Props) {
	const freeWordLimit = useFreeWordLimit()
	const dispatch = useDispatch()
	const customSourceFF = useFeatureFlagVariantKey(FEATURE_FLAGS.CUSTOM_SOURCE)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, props.docID))
	const isPremium = useIsPremium()

	//Set redux if we have local storage for this document on component mount
	useEffect(() => {
		if (!isEqual(props.localUserWriterSelections, blankUserWriterSelections)) {
			dispatch(
				setUserWriterSelections({
					docID: props.docID,
					userWriterSelections: props.localUserWriterSelections,
				})
			)
		}
		//Update only on initial page load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const pageCount = useMemo(() => {
		const wordCount = documentState.userWriterSelections?.wordCount ?? freeWordLimit
		return (wordCount / 500).toFixed(1)
	}, [documentState.userWriterSelections?.wordCount])

	const maxWordCount = CONSTANTS.WORD_COUNT_MAX

	return (
		<>
			<div id="writerOptions">
				{props.type !== DOC_TEMPLATES.Outline && (
					<>
						<h4 className="sliderValue mb-3 md:text-5xl text-4xl mt-12">
							<input
								onChange={(e) => {
									dispatch(
										setUserWriterSelections({
											docID: props.docID,
											userWriterSelections: { wordCount: parseInt(e.target.value) },
										})
									)
								}}
								onBlur={() => {
									const input = documentState.userWriterSelections?.wordCount ?? freeWordLimit
									if (input > maxWordCount) {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { wordCount: maxWordCount },
											})
										)
									} else if (input < CONSTANTS.WORD_COUNT_MIN) {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { wordCount: CONSTANTS.WORD_COUNT_MIN },
											})
										)
									}
								}}
								type="number"
								className="value !w-[130px]"
								value={documentState.userWriterSelections?.wordCount ?? freeWordLimit}
								placeholder=""
							/>
							<span className="units fancy-font">
								words
								<span className="md:inline hidden">, or ~{pageCount} pages</span>
							</span>
						</h4>
						<input
							type="range"
							min={CONSTANTS.WORD_COUNT_MIN}
							max={maxWordCount}
							step={50}
							className="slider"
							value={documentState.userWriterSelections?.wordCount ?? freeWordLimit}
							onChange={(e) => {
								dispatch(
									setUserWriterSelections({
										docID: props.docID,
										userWriterSelections: { wordCount: parseInt(e.target.value) },
									})
								)
							}}
						/>
						{isPremium && (
							<>
								<div className="humanizerContainer">
									<div className="text-xl mb-3 font-semibold">Humanizer intensity</div>
									<div className="grid md:grid-cols-4 grid-cols-2 gap-3">
										<>
											{HUMANIZER_OPTIONS.map((str, index) => {
												return (
													<Button
														className={`w-full text-xl ${
															str === documentState.userWriterSelections?.humanizerIntensity && 'bg-secondary'
														}`}
														key={str + index}
														type="secondary"
														onClick={(e) => {
															dispatch(
																setUserWriterSelections({
																	docID: props.docID,
																	userWriterSelections: { humanizerIntensity: str },
																})
															)
														}}
													>
														<span>{str}</span>
													</Button>
												)
											})}
										</>
									</div>
								</div>
							</>
						)}
					</>
				)}
				<div className="toneTypeContainer">
					<div className="text-xl mb-3 font-semibold text-center">Select an essay type</div>
					<div className="grid md:grid-cols-5 grid-cols-1 gap-3 toneTypeList">
						{TYPE_OPTIONS.map((str, index) => {
							return (
								<Button
									className={`toneTypeButton ${str === documentState.userWriterSelections?.type && 'selected'}`}
									key={str + index}
									type="primary"
									onClick={(e) => {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { type: str },
											})
										)
									}}
								>
									<span>{str}</span>
								</Button>
							)
						})}
					</div>
				</div>
				{props.type !== DOC_TEMPLATES.Outline && (
					<>
						<WritingStyleSelection docID={props.docID} />
						<ReferencesSelection docID={props.docID} />
					</>
				)}
			</div>
		</>
	)
}

export default WriterOptions
