import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { useLocalStorage } from '../helpers/utility'
import { useEffect, useMemo, useState } from 'react'

export const FEATURE_FLAGS = {
	WORD_COUNT: 'word-count-2',
	PHILIPPINES_PRICING: 'philippines-pricing',
	PADDLE: 'paddle',
	GRADER_V2: 'grader-v2',
	ASK_AI_PROMPT: 'ask-ai-prompt',
	MONTHLY_SUGGESTION: 'monthly-suggestion',
	CUSTOM_SOURCE: 'custom-source',
	HUMANIZER_V4: 'humanizer-v4',
	REORDER_SUBTOPICS: 'reorder-subtopics',
	PRICING_V3: 'pricing-v3',
	SENTENCE_HUMANIZER: 'sentence-humanizer',
	EXPERT_REVIEWER: 'expert-reviewer',
	SECTION_GENERATION_LOADING: 'section-loading',
	SHOW_FREE_LIMITATIONS: 'show-free-limitations',
	ESSAY_TYPE_DESCRIPTION: 'essay-type-description',
	UPGRADE_SIGNUP: 'upgrade-signup',
	LIMITED_OFFER_BANNER: 'limited-offer-banner',
	BOLD_FIRST_SELLING_POINT: 'bold-first-selling-point',
	REMOVE_UPGRADE_CURSIVE: 'remove-upgrade-cursive',
	NEW_ESSAY_BUTTON: 'new-essay-button',
	ALWAYS_SHOW_NAV: 'always-show-nav',
	DOCUMENT_VIEW_V2: 'document-view-v2',
	BOTTOM_NAV_WORD_COUNT: 'bottom-nav-word-count',
	BOTTOM_NAV_BUTTON_REDESIGN: 'bottom-nav-button-redesign',
	CUSTOM_REFERENCE_V2: 'custom-reference-v2',
}

export const usePricing = () => {
	const pricingV3 = useFeatureFlagVariantKey(FEATURE_FLAGS.PRICING_V3)
	const [countryCode, setCountryCode] = useState('US')

	useEffect(() => {
		fetch('https://ipapi.co/json/')
			.then((response) => response.json())
			.then((data) => {
				if (data.country_code === 'PH') {
					setCountryCode(data.country_code)
				}
			})
	}, [])

	const defaultPricing = {
		monthly: '9',
		yearly: '6',
		yearlyTotal: '72',
		discount: '33',
		currencySymbol: '$',
	}

	const defaultV3Pricing = {
		monthly: '19',
		yearly: '9',
		yearlyTotal: '108',
		discount: '50',
		currencySymbol: '$',
	}

	const philippinesPricing = {
		monthly: '300',
		yearly: '233',
		yearlyTotal: '2800',
		discount: '22',
		currencySymbol: '₱',
	}

	const philippinesV3Pricing = {
		monthly: '600',
		yearly: '300',
		yearlyTotal: '3600',
		discount: '50',
		currencySymbol: '₱',
	}

	const pricing = useMemo(() => {
		if (countryCode === 'PH') {
			return pricingV3 === 'test' ? philippinesV3Pricing : philippinesPricing
		}
		return pricingV3 === 'test' ? defaultV3Pricing : defaultPricing
	}, [countryCode, pricingV3])

	// if (['control', '40'].includes(philippinesPricingFF as string)) {
	// 	return {
	// 		monthly: philippinesPricing.monthly as string,
	// 		yearly: philippinesPricing.yearly as string | null,
	// 		yearlyTotal: philippinesPricing.yearlyTotal as string | null,
	// 		discount: philippinesPricing.discount as string | null,
	// 		stripePriceId: {
	// 			yearly: philippinesPricing.stripePriceId.yearly as string | null,
	// 			monthly: philippinesPricing.stripePriceId.monthly as string,
	// 		},
	// 	}
	// }

	return {
		...pricing,
		stripePriceId:
			pricingV3 === 'test'
				? {
						yearly: 'price_1Ql4PUApflHFLqu6oOYuVPJo',
						monthly: 'price_1Ql4N0ApflHFLqu6TTWi4K4L',
				  }
				: {
						yearly: 'price_1Ql4ogApflHFLqu6XPdDmIFI',
						monthly: 'price_1QnxlvApflHFLqu60NqtwX8v',
				  },
	}
}

export const useFreeWordLimit = () => {
	const variant = useFeatureFlagVariantKey(FEATURE_FLAGS.WORD_COUNT)

	const variantToLimit = {
		control: 250,
		'150': 150,
		'350': 350,
	}

	// @ts-ignore
	return variantToLimit[variant ?? 'control'] as 150 | 250 | 350
}

export const useAnnualPlan = () => {
	const [annualPlan, setAnnualPlan] = useLocalStorage('annualPlan', false)

	return [annualPlan, setAnnualPlan] as const
}
