import './_Thanks.scss'
import { useEffect, useMemo, useState } from 'react'
import { useAnnualPlan, usePricing } from '../../hooks/featureFlags'
import Modal from './Modal'
import { usePostHog } from 'posthog-js/react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocalStorage } from '../../helpers/utility'
import { TbConfetti } from 'react-icons/tb'
import Button from '../Buttons'
import { selectUser } from '../../redux/systemSlice'
import { USE_CASE } from '../../redux/types'
import { useAuth } from '../../contexts/Auth'
import { IoBook } from 'react-icons/io5'
import { FaGraduationCap } from 'react-icons/fa6'
import { PiBooksFill } from 'react-icons/pi'
import { FaBriefcase } from 'react-icons/fa'

const useCaseDisplay = {
	[USE_CASE.PRE_UNIVERSITY]: 'Pre-University',
	[USE_CASE.UNDERGRADUATE]: 'Undergraduate',
	[USE_CASE.GRADUATE]: 'Graduate',
	[USE_CASE.PROFESSIONAL]: 'Professional',
}

const useCaseIcons = {
	[USE_CASE.PRE_UNIVERSITY]: IoBook({ size: 16 }),
	[USE_CASE.UNDERGRADUATE]: FaGraduationCap({ size: 18 }),
	[USE_CASE.GRADUATE]: PiBooksFill({ size: 18 }),
	[USE_CASE.PROFESSIONAL]: FaBriefcase({ size: 16 }),
}

function Thanks() {
	const [thanksModalOpen, setThanksModalOpen] = useState(false)
	const [pageRefreshed, setPageRefreshed] = useLocalStorage('succeeessssfulPaymentReloads', false)
	const dispatch = useDispatch()
	const posthog = usePostHog()
	const price = usePricing()
	const user = useSelector(selectUser)
	const { currentUser } = useAuth() as any
	const searchParamsString = document.location.search
	const [annualPlan] = useAnnualPlan()
	const roleSelectorFF = 'test'
	const [useCase, setUseCase] = useState<USE_CASE | undefined>(user.useCase)

	const searchParams = useMemo(() => {
		let params = new URLSearchParams(searchParamsString)

		return {
			showModal: params.getAll('showModal')[0],
			paddleSuccess: params.getAll('paddleSuccess')[0],
			annual: params.getAll('annual')[0],
		}
	}, [searchParamsString])

	const capturePurchase = () => {
		posthog?.capture('purchase', {
			price: annualPlan ? price.yearlyTotal : price.monthly,
			plan: annualPlan ? 'annual' : 'monthly',
		})
	}

	useEffect(() => {
		if (searchParams.showModal) {
			setThanksModalOpen(true)
			const newSearchParams = new URLSearchParams(searchParamsString)
			newSearchParams.delete('showModal')
			const newUrl = window.location.pathname + (newSearchParams.toString() ? '?' + newSearchParams.toString() : '')
			window.history.replaceState(null, '', newUrl)
			capturePurchase()
		}
	}, [searchParams, dispatch])

	useEffect(() => {
		if (searchParams.paddleSuccess && pageRefreshed) {
			setThanksModalOpen(true)
			const newSearchParams = new URLSearchParams(searchParamsString)
			newSearchParams.delete('paddleSuccess')
			const newUrl = window.location.pathname + (newSearchParams.toString() ? '?' + newSearchParams.toString() : '')
			window.history.replaceState(null, '', newUrl)
			capturePurchase()
		}
	}, [searchParams, dispatch])

	useEffect(() => {
		if (searchParams.paddleSuccess && !pageRefreshed) {
			setPageRefreshed(true)
			window.location.reload()
		}
	}, [searchParams, pageRefreshed])

	const updateUserRole = async () => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({ user_id: user.id, useCase: useCase }),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/user/update/', requestOptions)
	}

	return (
		<Modal
			open={thanksModalOpen}
			closeModal={() => {
				setThanksModalOpen(false)
			}}
			portalClassName="thanks"
			omitClose={roleSelectorFF === 'test'}
		>
			<div
				className={`flex flex-col gap-2 items-center bg-secondary ${
					roleSelectorFF === 'test' ? 'pt-5 pb-5' : 'pt-10 pb-6'
				} px-3`}
			>
				<div className="w-12 h-12 bg-white rounded-full flex items-center justify-center">
					{TbConfetti({ className: 'text-2xl' })}
				</div>
				<div className={`text-2xl text-center ${roleSelectorFF === 'test' ? 'font-medium' : 'font-semibold'}`}>
					You're now a Premium member!
				</div>
				{roleSelectorFF === 'test' && (
					<div className="text-center text-sm">Help us customize your writing experience</div>
				)}
			</div>
			{roleSelectorFF === 'test' ? (
				<div className="flex flex-col gap-4 py-5 md:px-12 px-5">
					<div>Which role best describes you?</div>
					{Object.values(USE_CASE).map((role) => (
						<div
							key={role}
							className={`py-2 border border-gray-900 px-4 flex items-center cursor-pointer gap-3 rounded font-medium ${
								useCase === role ? 'bg-secondary hover:bg-[#a1edeb]' : 'hover:bg-gray-100'
							}`}
							onClick={() => {
								setUseCase(role)
							}}
						>
							{useCaseIcons[role]}
							{useCaseDisplay[role]}
						</div>
					))}
					<div className="grid md:grid-cols-3 grid-cols-2 gap-2 mt-2">
						<div>
							<Button
								type="ghost"
								className="text-sm font-normal text-gray-700 text-left"
								onClick={() => setThanksModalOpen(false)}
							>
								Skip for now
							</Button>
						</div>
						<div className="md:text-center text-right">
							<Button
								type="default"
								disabled={!useCase}
								onClick={() => {
									updateUserRole()
									setThanksModalOpen(false)
								}}
							>
								Continue
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-2 md:px-10 px-5 py-4">
					<div>
						Enjoy <b>unlimited</b> access to all of our premium features, including:
						<ul className="list-disc list-inside mt-2">
							<li className="text-[16px] mb-0">2500 word essay generations</li>
							<li className="text-[16px] mb-0">Unlimited humanization through our AI detection bypasser</li>
							<li className="text-[16px] mb-0">Unlimited AI detection checks</li>
						</ul>
					</div>
					<Button type="secondary" emphasize className="py-2" onClick={() => setThanksModalOpen(false)}>
						Get Started
					</Button>
				</div>
			)}
		</Modal>
	)
}

export default Thanks
