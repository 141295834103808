import React, { useEffect, useState } from 'react'
import Modal from './Modal'
import { DocumentState } from '../../redux/types'
import { useSelector } from 'react-redux'
import { selectDocumentState } from '../../redux/docSlice'
import { useDocId } from '../../hooks/docID'
import { htmlToString, isValidEmail } from '../../helpers/utility'
import Button from '../Buttons'
import { useWordCount } from '../../Pages/Humanizer/hooks'
import { selectUser } from '../../redux/systemSlice'
import { usePostHog } from 'posthog-js/react'
import { TextareaAutosize } from '@mui/material'

const HumanReviewerModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [email, setEmail] = useState('')
	const [essayContent, setEssayContent] = useState('')
	const [file, setFile] = useState<File | null>(null)
	const [fileName, setFileName] = useState<string | null>(null)
	const docId = useDocId()
	const documentState: DocumentState | null = useSelector((state) => selectDocumentState(state, docId))
	const wordCount = useWordCount(essayContent)
	const user = useSelector(selectUser)
	const posthog = usePostHog()
	const isOnDashboard = window.location.pathname === '/choose'

	useEffect(() => {
		if (user?.email) {
			setEmail(user ? user.email : '')
		}
	}, [user?.email])

	useEffect(() => {
		if (!isOnDashboard && documentState?.content) {
			setEssayContent(htmlToString(documentState.content))
		}
	}, [documentState?.content, isOnDashboard])

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = event.target.files?.[0]
		if (selectedFile) {
			setFile(selectedFile)
			setFileName(selectedFile.name)
		}
	}

	const handleButtonClick = () => {
		document?.getElementById('fileInput')?.click()
	}

	const handleSubmit = async () => {
		try {
			setLoading(true)
			// Create a form data object
			const formData = new FormData()
			formData.append('email', email)
			formData.append('essay', essayContent)
			formData.append('word_count', wordCount.toString())
			formData.append('user_id', user.id)

			if (file) {
				formData.append('rubric', file)
			}

			const response = await fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/submit-review-essay/', {
				method: 'POST',
				body: formData,
			})

			if (!response.ok) {
				// Handle error response
				const errorData = await response.json()
				alert(`Error submitting data: ${errorData.error || 'Unknown error'}`)
				setLoading(false)
				return
			}

			// If successful, handle success message or do something else
			posthog.capture('submit-essay-review')

			setSuccess(true)
			setLoading(false)
		} catch (error) {
			setLoading(false)
			console.error('Submission error:', error)
			alert('An unexpected error occurred. Please try again.')
		}
	}

	return (
		<Modal
			open={open}
			closeModal={() => {
				onClose()
				setSuccess(false)
				setEssayContent('')
				setEmail('')
				setFile(null)
				setFileName(null)
			}}
			portalClassName="rubric large"
		>
			{success ? (
				<div className="flex flex-col gap-2 h-48 justify-center items-center">
					<div className="md:text-2xl text-xl font-semibold text-center">Thank you for submitting your essay!</div>
					<div className="text-sm text-center text-gray-700">
						You will receive an email with your reviewed essay within 48 hours.
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-2">
					<div className="md:text-2xl text-xl font-semibold text-center">Get Your Essay Reviewed by an Expert</div>
					<div className="text-sm text-center text-gray-800">
						Submit your essay for a detailed, professional review. Our experts will edit your essay and provide feedback
						based on your rubric within 48 hours.
					</div>
					<div className="flex flex-col gap-3">
						<div className="flex flex-col gap-1">
							<div className="font-semibold text-sm">Email address:</div>
							<input
								placeholder="me@example.com"
								onChange={(e) => {
									setEmail(e.target.value)
								}}
								value={email}
								type={'email'}
								className="text-input border border-gray-500 focus:border-gray-700 block w-full p-[8px] outline-none rounded"
							/>
						</div>
						{isOnDashboard ? (
							<div className="flex flex-col gap-1">
								<div className="font-semibold text-sm">Paste your essay:</div>
								<TextareaAutosize
									className="border border-gray-600 rounded w-full p-2 overflow-auto outline-none resize-none text-sm"
									placeholder="Paste text here..."
									minRows={10}
									maxRows={10}
									autoFocus
									value={essayContent}
									onChange={(e) => {
										setEssayContent(e.target.value)
									}}
								/>
							</div>
						) : (
							<div className=" rounded w-full p-2 overflow-auto text-sm bg-gray-100 max-h-48">
								<div>
									{htmlToString(documentState?.content ?? '')
										.split('\n')
										.map((line, index) => (
											<React.Fragment key={index}>
												{line}
												<br />
											</React.Fragment>
										))}
								</div>
							</div>
						)}
						<div className="flex justify-between mt-3 flex-wrap gap-2">
							<div className="font-semibold text-sm mt-2">Rubric upload (optional):</div>
							<div className="flex flex-col items-end gap-1">
								<input
									type="file"
									id="fileInput"
									accept=".pdf, .png, .jpg, .jpeg"
									onChange={handleFileChange}
									className="hidden"
								/>
								<Button type="outline" onClick={handleButtonClick}>
									{fileName ? 'Reupload' : 'Upload'} File
								</Button>
								<span className="text-gray-700 truncate max-w-48">{fileName}</span>
							</div>
						</div>
						<div className="border-t border-gray-300 my-3"></div>
						<div className="flex justify-between items-center flex-wrap gap-2">
							<div className="text-gray-700">
								<span className="font-semibold">Price: </span>
								<span className="font-semibold">
									$0.02 * {wordCount} words ={' '}
									<span className="font-bold text-black">${Number(wordCount * 0.02).toFixed(2)}</span>
								</span>
							</div>
							<Button
								type="default"
								onClick={handleSubmit}
								disabled={loading || !email || !essayContent || !isValidEmail(email)}
								className="shrink-0"
							>
								{loading ? 'Submitting...' : 'Submit & Pay'}
							</Button>
						</div>
					</div>
				</div>
			)}
		</Modal>
	)
}

export default HumanReviewerModal
