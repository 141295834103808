import { useDispatch, useSelector } from 'react-redux'
import { DocumentState } from '../../redux/types'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { useEffect, useState } from 'react'
import Modal from '../Modals/Modal'
import { EditorContent, useEditor } from '@tiptap/react'
import Placeholder from '@tiptap/extension-placeholder'
import Button from '../Buttons'
import { LuListPlus, LuSparkles } from 'react-icons/lu'
import { selectUser } from '../../redux/systemSlice'
import { useAtom } from 'jotai'
import { docLoadingAtom } from '../../Pages/Create/Create'
import { debounce } from 'lodash'
import { TbSparkles } from 'react-icons/tb'
import { writingStyleDescriptions } from '../../redux/constants'

const CustomWritingStyleModal = ({
	docID,
	modalOpen,
	setModalOpen,
}: {
	docID: string
	modalOpen: boolean
	setModalOpen: (open: boolean) => void
}) => {
	const [docLoading] = useAtom(docLoadingAtom)
	const [userWritingStyleSet, setUserWritingStyleSet] = useState(false)
	const [showResult, setShowResult] = useState(false)
	const [loading, setLoading] = useState(false)
	const editor = useEditor({
		extensions: [
			Document,
			Text,
			Paragraph,
			Placeholder.configure({
				placeholder: 'Paste your sample text here...',
			}),
		],
		content: '',
	})
	const describeStyleEditor = useEditor({
		extensions: [
			Document,
			Text,
			Paragraph,
			Placeholder.configure({
				placeholder: 'Describe your writing style here...',
			}),
		],
		content: '',
	})
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const dispatch = useDispatch()
	const user = useSelector(selectUser)

	useEffect(() => {
		if (user.writingStyle && documentState?.userWriterSelections && !userWritingStyleSet && !docLoading && docID) {
			setShowResult(true)
			setUserWritingStyleSet(true)
			const debouncedTabKeyPress = debounce(() => {
				dispatch(
					setUserWriterSelections({
						docID: docID,
						userWriterSelections: {
							...documentState.userWriterSelections,
							writingStyle: {
								...documentState.userWriterSelections.writingStyle,
								description: user.writingStyle.shortDescription,
								longDescription: user.writingStyle.longDescription,
								sampleText: user.writingStyle.sampleText,
							},
						},
					})
				)
			}, 200)
			debouncedTabKeyPress()
		}
	}, [user.writingStyle, docID, dispatch, documentState?.userWriterSelections, userWritingStyleSet, docLoading, docID])

	const generateCustomWritingStyle = () => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				referenceText: editor?.getText(),
				descriptionText: undefined,
				user_id: user.id,
			}),
		}
		setLoading(true)

		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/generate-writing-style/', requestOptions)
			.then((res) => res.json())
			.then((response: { shortDescription: string; longDescription: string; sampleText: string }) => {
				setLoading(false)
				setShowResult(true)

				const text = editor?.getText().split(' ').slice(0, 500).join(' ')
				dispatch(
					setUserWriterSelections({
						docID: docID,
						userWriterSelections: {
							writingStyle: {
								referenceText: text,
								description: response.shortDescription,
								longDescription: response.longDescription,
								sampleText: response.sampleText,
								generalSelected: false,
								name: 'Create & Edit',
							},
						},
					})
				)
			})
			.catch((err) => {
				console.error(err)
			})
	}

	return (
		<Modal
			open={modalOpen}
			closeModal={() => {
				setModalOpen(false)
				if (documentState.userWriterSelections.writingStyle?.description) {
					setShowResult(true)
				}
			}}
			portalClassName="customWritingStyle"
		>
			<div className="flex flex-col gap-4 mt-5">
				{showResult ? (
					<div className="flex flex-col gap-1">
						<div className="text-xl font-semibold text-center">
							Your custom writing style {LuSparkles({ className: 'inline-block ml-0.5 mb-1' })}
						</div>
						<div className="text-sm text-gray-800 text-center">
							Your custom writing style has been generated. Below, you'll find a description of your style along with a
							sample text that demonstrates it.
						</div>
					</div>
				) : (
					<div className="flex flex-col gap-1">
						<div className="text-xl font-semibold text-center">Create a Style</div>
						<div className="text-sm text-gray-800 text-center">
							Paste a sample of your writing below to create a personalized AI writing style that captures your unique
							voice and tone.
						</div>
					</div>
				)}
				<div className="flex flex-col gap-4">
					{showResult ? (
						<>
							<div className="h-1 border-b border-gray-400" />
							<div className="text-sm">
								<span className="font-semibold">Description:</span>{' '}
								{documentState.userWriterSelections.writingStyle?.description}
							</div>
							<div className="flex flex-col gap-2">
								<div className="text-sm font-semibold">Sample Text:</div>
								<div className="h-48 bg-gray-100 text-sm rounded border border-gray-400 p-3 overflow-y-auto">
									{documentState.userWriterSelections.writingStyle?.sampleText}
								</div>
							</div>
						</>
					) : (
						<>
							<div>
								<EditorContent
									editor={editor}
									id="custom-style-editor"
									className="border border-gray-500 rounded max-h-96 overflow-auto"
									placeholder="Paste your sample text here..."
								/>
								<div className="text-sm text-gray-700">Minimum 20 words</div>
							</div>
						</>
					)}
					{showResult ? (
						<Button
							type="outline"
							onClick={() => {
								setShowResult(false)
							}}
						>
							Edit Style
						</Button>
					) : (
						<Button
							type="default"
							disabled={(editor?.getText().split('').length ?? 0) < 20 || loading}
							onClick={() => {
								generateCustomWritingStyle()
							}}
						>
							{loading ? (
								'Thinking...'
							) : documentState.userWriterSelections.writingStyle.description ? (
								<span>Save changes</span>
							) : (
								<span>Generate Style</span>
							)}
						</Button>
					)}
				</div>
			</div>
		</Modal>
	)
}

const WritingStyleSelectionV1 = ({ docID, omitHeader = false }: { docID: string; omitHeader?: boolean }) => {
	const [modalOpen, setModalOpen] = useState(false)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const dispatch = useDispatch()
	if (!documentState.userWriterSelections.writingStyle) {
		return null
	}
	const generalSelected = !!documentState.userWriterSelections.writingStyle?.generalSelected
	const customWritingStyleGenerated = !!documentState.userWriterSelections.writingStyle?.longDescription

	return (
		<>
			<CustomWritingStyleModal docID={docID} modalOpen={modalOpen} setModalOpen={setModalOpen} />
			<div className="flex flex-col gap-4 mt-10">
				{!omitHeader && <div className={`text-xl font-semibold `}>Writing Style</div>}
				<div className={`grid grid-cols-[1fr,1fr] md:gap-8 gap-2 justify-center flex-wrap`}>
					<div
						className={`cursor-pointer border px-6 py-4 rounded border-gray-600 flex flex-col hover:shadow-md ${
							generalSelected ? 'bg-secondary' : 'border-dotted'
						}`}
						onClick={() => {
							dispatch(
								setUserWriterSelections({
									docID: docID,
									userWriterSelections: {
										...documentState.userWriterSelections,
										writingStyle: {
											...documentState.userWriterSelections.writingStyle,
											generalSelected: true,
										},
									},
								})
							)
						}}
					>
						<div className="text-lg font-semibold">Standard</div>
						<div className="text-sm text-gray-800">College reading level with an academic tone.</div>
					</div>
					<div
						className={`cursor-pointer border px-6 py-4 rounded border-gray-600 flex flex-col items-start hover:shadow-md ${
							!generalSelected ? 'bg-secondary' : 'border-dotted'
						}`}
						onClick={() => {
							if (customWritingStyleGenerated) {
								dispatch(
									setUserWriterSelections({
										docID: docID,
										userWriterSelections: {
											writingStyle: {
												...documentState.userWriterSelections.writingStyle,
												generalSelected: false,
											},
										},
									})
								)
							} else {
								setModalOpen(true)
							}
						}}
					>
						<div className="flex gap-1 justify-between w-full mb-1 items-center">
							<div className="flex gap-1 items-center">
								{customWritingStyleGenerated && TbSparkles({})}
								<div className="text-lg font-semibold">
									{customWritingStyleGenerated ? 'Your Style' : 'Custom Writing Style'}
								</div>
							</div>
							{customWritingStyleGenerated && !generalSelected && (
								<Button
									type="outline"
									emphasize={!customWritingStyleGenerated}
									className="bg-white text-sm !py-1 hidden md:block"
									onClick={() => {
										setModalOpen(true)
									}}
								>
									Edit
								</Button>
							)}
						</div>
						<div className="text-sm text-gray-800">
							{customWritingStyleGenerated
								? documentState.userWriterSelections.writingStyle?.description
								: 'Paste in a sample text to personalize your writing style.'}
						</div>
						{customWritingStyleGenerated && !generalSelected && (
							<Button
								type="outline"
								emphasize={!customWritingStyleGenerated}
								className="bg-white text-sm !py-1 mt-2 block md:hidden"
								onClick={() => {
									setModalOpen(true)
								}}
							>
								Edit Style
							</Button>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

const WritingStyleSelectionV2 = ({ docID, omitHeader = false }: { docID: string; omitHeader?: boolean }) => {
	const [modalOpen, setModalOpen] = useState(false)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const dispatch = useDispatch()
	if (!documentState.userWriterSelections.writingStyle) {
		return null
	}

	return (
		<>
			<CustomWritingStyleModal docID={docID} modalOpen={modalOpen} setModalOpen={setModalOpen} />
			<div className="flex flex-col gap-4 mt-10">
				{!omitHeader && <div className={`text-xl font-semibold `}>Writing Style</div>}
				<div className={`grid md:grid-cols-3 grid-cols-1 gap-3`}>
					{['Standard', 'Academic', 'Professional', 'Casual', 'Creative', 'Create & Edit'].map((str, index) => {
						return (
							<Button
								className={`w-full text-lg rounded py-1 font-medium ${
									str === documentState.userWriterSelections?.writingStyle.name
										? ''
										: 'hover:bg-gray-100 border-dotted border border-gray-500'
								}`}
								key={str + index}
								type="primary"
								emphasize={str === documentState.userWriterSelections?.writingStyle.name}
								onClick={(e) => {
									if (str === 'Create & Edit') {
										setModalOpen(true)
										return
									}
									dispatch(
										setUserWriterSelections({
											docID: docID,
											userWriterSelections: {
												...documentState.userWriterSelections,
												writingStyle: {
													...documentState.userWriterSelections.writingStyle,
													generalSelected: false,
													customSelected: index === 5,
													name: str,
													// @ts-ignore
													longDescription: writingStyleDescriptions[str],
												},
											},
										})
									)
								}}
							>
								<div className="flex justify-center gap-1 items-center">
									{index === 5 && LuListPlus({ size: 18 })}
									{str}
								</div>
							</Button>
						)
					})}
				</div>
			</div>
		</>
	)
}

const WritingStyleSelection = ({ docID, omitHeader = false }: { docID: string; omitHeader?: boolean }) => {
	return <WritingStyleSelectionV1 docID={docID} omitHeader={omitHeader} />
}

export default WritingStyleSelection
