import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import Premium from '../Premium/Premium'
import Modal from './Modal'
import './_Subscriptions.scss'
import { useEffect } from 'react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'

function Subscriptions({
	open,
	closeModal,
	annualPlan,
	setAnnualPlan,
	setLoginModal,
	noMoreCredits = false,
}: {
	open: boolean
	closeModal: () => void
	annualPlan: boolean
	setAnnualPlan: (annual: boolean) => void
	setLoginModal: (open: boolean) => void
	noMoreCredits?: boolean
}) {
	const limitedOfferBannerFF = useFeatureFlagVariantKey(FEATURE_FLAGS.LIMITED_OFFER_BANNER)
	const posthog = usePostHog()

	useEffect(() => {
		posthog.capture('upgrade-modal-opened')
	}, [open])

	return (
		<Modal
			open={open}
			closeModal={() => {
				closeModal()
				posthog.capture('upgrade-modal-closed')
			}}
			portalClassName="subscription"
		>
			{limitedOfferBannerFF === 'test' && (
				<div className="w-full bg-danger-light text-danger-dark py-2.5 font-semibold md:pl-10 pl-4">
					Limited Offer: All plans up to 50% off
				</div>
			)}
			<div className={`flex-container md:p-11 ${limitedOfferBannerFF === 'test' ? 'md:pt-8' : 'md:pt-11'} p-4`}>
				<div className="pro">
					{
						<Premium
							closeParentModal={closeModal}
							setLoginModal={setLoginModal}
							annualPlan={annualPlan}
							setAnnualPlan={setAnnualPlan}
							noMoreCredits={noMoreCredits}
						/>
					}
				</div>
			</div>
		</Modal>
	)
}

export default Subscriptions
