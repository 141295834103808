import { FaQuestionCircle } from 'react-icons/fa'
import Modal from './Modal'
import './_SuggestMonthly.scss'
import { usePricing } from '../../hooks/featureFlags'
import Button from '../Buttons'
import useCheckout from '../../hooks/checkout'

function SuggestMonthly({ open, closeModal }: { open: boolean; closeModal: () => void }) {
	const pricing = usePricing()
	const { checkout } = useCheckout()

	const handleCheckout = () => {
		checkout(false)
	}

	return (
		<Modal open={open} closeModal={closeModal} portalClassName="suggest">
			<div className="flex flex-col items-center gap-2">
				<div className="p-2 rounded-full bg-secondary">
					{FaQuestionCircle({ size: 30, className: 'bg-white rounded-full text-primary' })}
				</div>
				<div className="text-lg font-semibold text-center">Are you looking for our monthly plan?</div>
				<div className="text-center text-gray-700">
					We noticed that you didn't complete the checkout process. If you meant to subscribe to our monthly plan at{' '}
					<span className="text-black font-semibold">${pricing.monthly} / month</span>, please click the button below to
					complete your checkout.
				</div>
				<div className="grid grid-cols-2 gap-4 mt-6">
					<Button
						type="primary"
						onClick={closeModal}
						className="py-2 px-3 border font-semibold border-gray-700 hover:bg-gray-100 outline-none"
					>
						No thanks
					</Button>
					<Button
						type="primary"
						onClick={handleCheckout}
						emphasize
						className="py-2 px-3 border font-semibold outline-none"
					>
						Purchase monthly plan
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default SuggestMonthly
