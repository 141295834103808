import { useState } from 'react'
import { LuInfo } from 'react-icons/lu'
import Modal from '../../Components/Modals/Modal'

const StrengthExplainerModal = () => {
	const [modalOpen, setModalOpen] = useState(false)

	return (
		<>
			{LuInfo({ className: 'cursor-pointer', onClick: () => setModalOpen(true) })}
			<Modal open={modalOpen} closeModal={() => setModalOpen(false)}>
				<div className="flex flex-col gap-3 p-5 items-center text-center max-w-2xl">
					<div className="text-2xl font-semibold">Humanization Strength</div>
					<div className="border-b border-gray-500 w-full"></div>
					<div className="text-xl font-semibold">Light</div>
					Ideal for bloggers, this mode emphasizes high readability and flawless grammar.
					<div className="text-xl font-semibold">Medium</div>
					Perfect for students looking to bypass Turnitin and other academic AI detectors. Balances AI detection evasion
					with optimal readability.
					<div className="text-xl font-semibold">Strong</div>
					Use to bypass all AI detection. Prioritizes undetectable content over readability for industry-leading bypass
					rates.
				</div>
			</Modal>
		</>
	)
}

export default StrengthExplainerModal
