import { FaRegCircleCheck } from 'react-icons/fa6'
import Modal from './Modal'
import { LuCircleX } from 'react-icons/lu'
import Button from '../Buttons'
import { usePostHog } from 'posthog-js/react'

const ReviewerSelectionModal = ({
	open,
	onClose,
	onReviewerSelect,
}: {
	open: boolean
	onClose: () => void
	onReviewerSelect: (reviewer: 'ai' | 'human') => void
}) => {
	const posthog = usePostHog()
	if (!open) return null

	return (
		<Modal open={open} closeModal={onClose} portalClassName="rubric large">
			<div className="flex flex-col gap-2">
				<div className="md:text-2xl text-xl font-semibold text-center">Get Your Paper Reviewed</div>
				<div className="text-sm text-center">
					Choose between an instant AI-powered review or a detailed human review from an expert.
				</div>
				<div className="grid md:grid-cols-[1fr,1fr] md:my-3 my-1 md:gap-0 gap-3">
					<div className="flex flex-col gap-2 items-start p-5">
						<div className="text-xl font-semibold">AI Review</div>
						<div className="text-sm text-gray-700">Get instant, basic feedback on your essay for free.</div>
						{['Basic feedback', 'Takes a few seconds'].map((item, index) => (
							<div key={index} className="flex gap-2 items-center">
								{FaRegCircleCheck({ className: 'text-success-default text-xl shrink-0' })}
								<div>{item}</div>
							</div>
						))}
						{['No detailed feedback', 'No editing', 'No custom rubric'].map((item, index) => (
							<div key={index} className="flex gap-2 items-center">
								{LuCircleX({ className: 'text-danger-default text-xl shrink-0' })}
								<div>{item}</div>
							</div>
						))}
						<Button
							type="default"
							onClick={() => {
								onReviewerSelect('ai')
							}}
							className="mt-2"
						>
							Get AI Review
						</Button>
					</div>
					<div className="flex flex-col gap-2 items-start shadow p-5 border-dotted border rounded border-black">
						<div className="text-xl font-semibold">Expert Review</div>
						<div className="text-sm text-gray-700">Detailed feedback and editing from a real human expert.</div>
						{['Detailed feedback', 'Quality editing', 'Custom rubric', '48-hour delivery', 'Low cost'].map(
							(item, index) => (
								<div key={index} className="flex gap-2 items-center">
									{FaRegCircleCheck({ className: 'text-success-default text-xl shrink-0' })}
									<div>{item}</div>
								</div>
							)
						)}
						<Button
							type="default"
							onClick={() => {
								onReviewerSelect('human')
								posthog.capture('expert-reviewer-selected')
							}}
							className="mt-2"
						>
							Get Expert Review
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default ReviewerSelectionModal
