import { selectDocumentState, setReferences } from '../../redux/docSlice'
import { DocumentState } from '../../redux/types'
import { useDocId } from '../../hooks/docID'
import { useDispatch, useSelector } from 'react-redux'
import ReferencesDropdown from '../WriterOptions/ReferencesDropdown'
import { useAuth } from '../../contexts/Auth'
import { formatReference } from '../../helpers/strings'
import { FaRegTrashAlt } from 'react-icons/fa'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { useMemo } from 'react'

const References = () => {
	const docId = useDocId()
	const dispatch = useDispatch()
	const { currentUser } = useAuth() as any
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))
	const documentViewV2 = useFeatureFlagVariantKey(FEATURE_FLAGS.DOCUMENT_VIEW_V2)

	const sortedReferences = useMemo(() => {
		if (!documentState?.references) return []
		return [...documentState.references].sort((a, b) => {
			if (a.custom && !b.custom) return -1
			if (!a.custom && b.custom) return 1
			return 0
		})
	}, [documentState?.references])

	const removeReference = async (index: number) => {
		let newReferences = [...(documentState?.references ?? [])]
		newReferences.splice(index, 1)
		dispatch(
			setReferences({
				docID: docId,
				references: newReferences,
			})
		)
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				id: docId,
				references: newReferences,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/document/update/', requestOptions)
	}

	if (
		!documentState ||
		documentState.isGenerating ||
		!documentState.setup ||
		!documentState.userWriterSelections.refStyle ||
		documentState.references.length === 0
	) {
		return null
	}

	return (
		<div className="flex flex-col gap-3 mt-8">
			<div className="flex justify-between items-center">
				<div className="flex gap-4 items-center">
					<div className="text-2xl font-semibold">References</div>
				</div>
				<ReferencesDropdown docID={docId} includeNull={false} />
			</div>
			<div className="flex flex-col gap-6">
				{sortedReferences.map((ref, index) => (
					<div key={index} className="grid grid-cols-[1fr,0] items-center overflow-hidden sm:overflow-visible">
						<div
							className={`text-lg leading-loose primary-font break-all ${
								documentViewV2 === 'test' ? '!text-[17px]' : ''
							}`}
						>
							{formatReference(documentState.userWriterSelections.refStyle!, ref, index)}
						</div>
						<div
							className={`cursor-pointer relative group sm:block w-10 ${
								documentViewV2 === 'test' ? 'md:left-3' : 'md:left-8'
							}`}
							onClick={() => removeReference(index)}
						>
							{FaRegTrashAlt({ className: 'cursor-pointer text-gray-500 group-hover:text-primary' })}
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default References
