import { FiUpload } from 'react-icons/fi'
import { TbFileUpload } from 'react-icons/tb'
import { IoMdClose } from 'react-icons/io'
import Button from '../Buttons'
import { useState, useRef, DragEvent, ChangeEvent } from 'react'
import { useDocId } from '../../hooks/docID'
import { useDispatch, useSelector } from 'react-redux'
import { selectDocumentState, setAllReferences, setReferences } from '../../redux/docSlice'
import { DocumentState } from '../../redux/types'
import { LuFileCheck } from 'react-icons/lu'
import { v4 as uuidv4 } from 'uuid'
import useLoadingDots from '../../hooks/loadingDots'
import { useAuth } from '../../contexts/Auth'
import { usePostHog } from 'posthog-js/react'
import { Bounce, toast } from 'react-toastify'

const CustomReference = () => {
	const docId = useDocId()
	const [showAddCustomReferenceSection, setShowAddCustomReferenceSection] = useState(false)
	const [uploadingFile, setUploadingFile] = useState(false)
	const [selectedFile, setSelectedFile] = useState<File | null>(null)
	const [isDragging, setIsDragging] = useState(false)
	const fileInputRef = useRef<HTMLInputElement>(null)
	const dispatch = useDispatch()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))
	const loadingDots = useLoadingDots(uploadingFile)
	const { currentUser } = useAuth() as any
	const posthog = usePostHog()

	const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()
		setIsDragging(true)
	}

	const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()
		setIsDragging(false)
	}

	const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()
		setIsDragging(false)

		const files = e.dataTransfer.files
		if (files && files.length > 0) {
			const file = files[0]
			if (file.type === 'application/pdf' && file.size <= 10 * 1024 * 1024) {
				setSelectedFile(file)
			} else {
				// You might want to show an error message here
				console.error('Invalid file type or size')
			}
		}
	}

	const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files
		if (files && files.length > 0) {
			const file = files[0]
			if (file.type === 'application/pdf' && file.size <= 10 * 1024 * 1024) {
				setSelectedFile(file)
			} else {
				// You might want to show an error message here
				console.error('Invalid file type or size')
			}
		}
	}

	const handleUploadClick = () => {
		fileInputRef.current?.click()
	}

	const uploadFile = async () => {
		posthog.capture('custom-reference-uploaded')
		if (!selectedFile) return
		setUploadingFile(true)

		try {
			const formData = new FormData()
			formData.append('file', selectedFile)

			const metadataResponse = await fetch(process.env.REACT_APP_API_ROOT + '/document/file-metadata/', {
				method: 'POST',
				body: formData,
			})
			const metadataData = await metadataResponse.json()
			if (!metadataData.title) {
				posthog.capture('custom-reference-uploaded-error')
				toast.error('Unable to parse PDF', {
					position: 'top-center',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
					transition: Bounce,
				})
				return
			}
			const id = uuidv4()
			const newReference = {
				id: id,
				active: true,
				title: metadataData.title,
				authors: metadataData.authors,
				authorsString: metadataData.authors.join(', '),
				publishedDate: metadataData.publishedDate,
				summary: metadataData.summary,
				url: selectedFile.name,
				custom: true,
			}

			// Get the latest state values
			const currentState = documentState
			const newReferences = [newReference, ...currentState.references]
			const newAllReferences = [newReference, ...currentState.allReferences]

			dispatch(
				setAllReferences({
					docID: docId,
					allReferences: newAllReferences,
				})
			)
			dispatch(
				setReferences({
					docID: docId,
					references: newReferences,
				})
			)

			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
				},
				body: JSON.stringify({
					id: docId,
					references: newReferences,
				}),
			}
			fetch(process.env.REACT_APP_API_ROOT + '/document/update/', requestOptions).catch((err) => {
				console.error('Error creating source:', err)
			})

			// Reset state after successful upload
			setSelectedFile(null)
			setShowAddCustomReferenceSection(false)
		} catch (error) {
			console.error('Error uploading file:', error)
		} finally {
			setUploadingFile(false)
		}
	}

	if (showAddCustomReferenceSection) {
		return (
			<div className="px-6 py-4 bg-white rounded-lg shadow border">
				<div className="flex justify-between items-center mb-3">
					<h2 className="text-xl font-medium">Add your own reference</h2>
					<Button
						type="ghost"
						onClick={() => setShowAddCustomReferenceSection(false)}
						className="text-gray-500 hover:text-gray-700"
					>
						{IoMdClose({ size: 20 })}
					</Button>
				</div>

				<div
					className={`border-2 border-dashed ${
						isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
					} rounded-lg px-8 py-6 cursor-pointer transition-colors`}
					onClick={handleUploadClick}
					onDragEnter={handleDragEnter}
					onDragLeave={handleDragLeave}
					onDragOver={handleDragOver}
					onDrop={handleDrop}
				>
					<input type="file" ref={fileInputRef} className="hidden" accept=".pdf" onChange={handleFileSelect} />
					<div className="flex flex-col items-center justify-center text-center">
						{selectedFile
							? LuFileCheck({ size: 38, className: 'text-success-default mb-3' })
							: TbFileUpload({ size: 38, className: 'text-gray-500 mb-3' })}
						<h3 className={`text-lg font-medium mb-2`}>
							{selectedFile ? selectedFile.name : 'Drag and drop file or click to browse'}
						</h3>
						<Button type="outline">Select File</Button>
						<p className="text-sm text-gray-500 mt-4">Supported: PDF (max 10MB)</p>
					</div>
				</div>

				<div className="mt-4 flex justify-center">
					<Button
						type="default"
						disabled={!selectedFile || uploadingFile}
						className={!selectedFile ? 'opacity-50 cursor-not-allowed' : ''}
						onClick={uploadFile}
					>
						{uploadingFile ? 'Uploading' + loadingDots : 'Upload and Add Reference'}
					</Button>
				</div>
			</div>
		)
	}

	return (
		<div
			className="py-2 px-3 border border-dashed border-gray-400 rounded-lg cursor-pointer hover:bg-gray-50 transition-colors"
			onClick={() => setShowAddCustomReferenceSection(true)}
		>
			<div className="flex justify-between gap-3 items-center">
				<div className="flex items-center gap-3">
					{FiUpload({ size: 22, className: 'text-gray-500' })}
					<div className="flex flex-col gap-0">
						<div className="text-lg font-semibold">Add your own reference</div>
						<div className="text-sm text-gray-600">Upload a PDF file to use as a reference.</div>
					</div>
				</div>
				<Button type="default">Add</Button>
			</div>
		</div>
	)
}

export default CustomReference
