import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDocumentState, setContent, setDrafts, setJSONContent } from '../../redux/docSlice'
import { selectJWToken, selectUser, setShowNav } from '../../redux/systemSlice'
import Ai from '@tiptap-pro/extension-ai-advanced'
import { ASK_AI_DESCRIPTIONS, ASK_AI_TYPES, CONSTANTS } from '../../constants'
import './_Editable.scss'
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Placeholder from '@tiptap/extension-placeholder'
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Dropdown from '../Dropdown/Dropdown'
import Button from '../Buttons'
import ControlledBubbleMenu from '../ControlledBubbleMenu/ControlledBubbleMenu'
import { useCookieUser } from '../../hooks/cookieUser'
import Diff from '../Diff/Diff'
import { htmlToString, completionToHTMLString } from '../../helpers/utility'
import { DocumentState } from '../../redux/types'
import ErrorModal from '../Modals/Error'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import Autocomplete from './Autocomplete'
import CopyEventHandler from './CopyEventHandler'
import { HoverMenuNode } from './HoverNode'
import EditableSpan from './EditableNode'
import TextareaAutosize from 'react-textarea-autosize'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import TypingExtension from './TypeEventHandler'
import SlashCommand from './SlashCommand'
import DragHandle from '@tiptap-pro/extension-drag-handle-react'
import { LuPlus } from 'react-icons/lu'
import useContentItemActions from './useContentItemActions'
import { useData } from './useData'
import AddMore from '../AddMore/AddMore'
import { FaCircleArrowUp } from 'react-icons/fa6'

type EditableProps = {
	docID: string
	type: string | undefined
}

function Document(props?: EditableProps) {
	const posthog = usePostHog()
	const dispatch = useDispatch()
	const user = useSelector(selectUser)
	const jwtToken = useSelector(selectJWToken)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, props?.docID ?? ''))
	const { userIDCookie } = useCookieUser()
	const [askAIDropdownOpen, setAskAIDropdownOpen] = useState(false)
	const [askAIBubbleMenuOpen, setAskAIBubbleMenuOpen] = useState(false)
	const [askAIType, setAskAIType] = useState<string | null>(null)
	const [promptInput, setPromptInput] = useState('')
	const closeAskAIDropdown = useRef(null)
	const [currSelection, setCurrSelection] = useState('')
	const [askAIGenerating, setAskAIGenerating] = useState(false)
	const [stagedContent, setStagedContent] = useState('')
	const [errorModalOpen, setErrorModalOpen] = useState(false)
	const [lastEditorContent, setLastEditorContent] = useState(CONSTANTS.EMPTY_DOCUMENT)
	const [showAutocompleteMenu, setShowAutocompleteMenu] = useState(false)
	const askAIPrompt = useFeatureFlagVariantKey(FEATURE_FLAGS.ASK_AI_PROMPT)
	const documentViewV2 = useFeatureFlagVariantKey(FEATURE_FLAGS.DOCUMENT_VIEW_V2)

	const handleUserType = () => {
		dispatch(setDrafts({ docID: props?.docID, drafts: [] }))
	}

	const editor = useEditor(
		{
			extensions: [
				StarterKit,
				EditableSpan,
				HoverMenuNode,
				SlashCommand,
				Placeholder.configure({
					placeholder: 'Start typing here and autocomplete the rest...',
				}),
				TypingExtension.configure({
					onUserType: handleUserType, // Pass the function
				}),
				Highlight.configure({ multicolor: true }),
				CopyEventHandler.extend({
					addOptions() {
						return {
							onCopy: () => {
								posthog.capture('copied-text')
							},
						}
					},
				}),
				Ai.configure({
					appId: 'wdv4zod2',
					token: jwtToken,
					autocompletion: true,
					onSuccess: (context) => {
						if ((context?.response?.length ?? 0) > 0) {
							setShowAutocompleteMenu(true)
							posthog.capture('autocomplete-triggered')
						}
					},
					aiStreamResolver: async ({ action, text, textOptions, extensionOptions }) => {
						if (text.endsWith('/')) {
							return new ReadableStream({
								start(controller) {
									controller.close()
								},
							})
						}
						const requestOptions = {
							method: 'POST',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								context: text,
								topic: documentState.title,
								docId: props?.docID,
								refStyle: documentState.userWriterSelections?.refStyle,
								use4o: false,
							}),
						}
						const response = await fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/autocomplete/', requestOptions)
						const json = await response?.json()
						if (!response.ok) {
							throw new Error(`${response.status} ${json?.message}`)
						}
						let completion = json?.completion

						return new ReadableStream({
							start(controller) {
								controller.enqueue(new TextEncoder().encode(completion))
								controller.close()
							},
						})
					},
				}),
			],
			content: documentState?.content ?? CONSTANTS.EMPTY_DOCUMENT,
			autofocus: true,
			onSelectionUpdate: ({ editor }) => {
				setShowAutocompleteMenu(false)
			},
			editable:
				documentState &&
				!documentState.isGenerating &&
				!documentState.isTypingAddMore &&
				!documentState.isTypingEssay &&
				!askAIGenerating,
			onUpdate({ editor }) {
				if (!documentState.isGenerating && !documentState.isTypingAddMore && !documentState.isTypingEssay) {
					dispatch(setShowNav({ value: false }))
				}
				if (editor?.getHTML() !== lastEditorContent) {
					dispatch(setContent({ docID: props?.docID, content: editor?.getHTML() }))
					dispatch(setJSONContent({ docID: props?.docID, jsonContent: editor.getJSON() }))
				}
				setLastEditorContent(editor?.getHTML() ?? CONSTANTS.EMPTY_DOCUMENT)
			},
		},
		[]
	)
	const data = useData()
	const actions = useContentItemActions(editor, data.currentNode, data.currentNodePos)

	useEffect(() => {
		if (!editor) return
		editor?.setEditable(
			documentState &&
				!documentState.isGenerating &&
				!documentState.isTypingAddMore &&
				!documentState.isTypingEssay &&
				!askAIGenerating
		)
		//Need specific control over the editor editable state, depending on updates to loading variables
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [documentState?.isGenerating, documentState?.isTypingAddMore, documentState?.isTypingEssay, askAIGenerating])

	//Update editor content when redux content changes
	useEffect(() => {
		if (!editor) return
		const { from, to } = editor.state.selection
		editor.commands.setContent(documentState?.content ?? CONSTANTS.EMPTY_DOCUMENT, false, {
			preserveWhitespace: 'full',
		})
		dispatch(setJSONContent({ docID: props?.docID, jsonContent: editor.getJSON() }))
		editor.commands.setTextSelection({ from, to })

		//This useEffect should ONLY trigger when documentState content is updated
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [documentState.content])

	//update current selection when user changes selection
	useEffect(() => {
		if (!editor) return
		const { from, to } = editor.state.selection
		setCurrSelection(editor.state.doc.textBetween(from, to))

		//This useEffect should ONLY trigger when editor selection is updated
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editor?.state.selection])

	const resetAskAIOptions = () => {
		if (!editor) return
		setAskAIBubbleMenuOpen(false)
		setAskAIType(null)
		setStagedContent('')
		editor.commands.setTextSelection({ from: 0, to: 0 })
	}

	const handleAskAIDropdownButtonClick = (e: any, options: { type?: string; prompt?: string }) => {
		e.preventDefault()
		if (!editor) return
		setAskAIDropdownOpen(false)
		setAskAIBubbleMenuOpen(true)
		setAskAIType(options.type ?? options.prompt ?? '')
		getAskAIResponse(options)
	}

	const renderStyleButtons = () => {
		if (!editor) return null
		return (
			<>
				{!askAIBubbleMenuOpen && (
					<>
						<div id="styleButtonContainer">
							<button
								onClick={() => editor.chain().focus().toggleBold().run()}
								className={`bubble-item${editor.isActive('bold') ? ' is-active' : ''}`}
							>
								<strong>{'B'}</strong>
							</button>
							<button
								onClick={() => editor.chain().focus().toggleItalic().run()}
								className={`bubble-item${editor.isActive('italic') ? ' is-active' : ''}`}
							>
								<em>{'i'}</em>
							</button>
							<button
								onClick={() => editor.chain().focus().toggleHighlight({ color: '#c9f2f1' }).run()}
								className={`bubble-item${editor.isActive('highlight') ? ' is-active' : ''}`}
							>
								<mark>{'H'}</mark>
							</button>
							<button
								onClick={() => editor.chain().focus().toggleStrike().run()}
								className={`bubble-item${editor.isActive('strike') ? ' is-active' : ''}`}
							>
								{<StrikethroughSIcon />}
							</button>
							<button
								onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
								className={`header bubble-item${editor.isActive('heading') ? ' is-active' : ''}`}
							>
								{'Header'}
							</button>
							{currSelection.split(' ').length >= 5 && currSelection.split(' ').length <= 200 && (
								<button
									onClick={() => {
										setAskAIDropdownOpen(!askAIDropdownOpen)
										posthog.capture('ask-ai-button-clicked')
									}}
									className={`ask-ai bubble-item${askAIDropdownOpen ? ' is-active' : ''}`}
									ref={closeAskAIDropdown}
								>
									Ask AI
									<AutoAwesomeIcon />
								</button>
							)}
						</div>
						<Dropdown
							open={askAIDropdownOpen}
							setOpen={(val: boolean) => {
								setAskAIDropdownOpen(val)
							}}
							body={askAiDropdownContent}
							closeButton={closeAskAIDropdown}
							className="askAIDropdown"
						/>
					</>
				)}
			</>
		)
	}

	async function getAskAIResponse(options: { type?: string; prompt?: string }) {
		if (!editor) return
		setAskAIGenerating(true)

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				title: documentState.title,
				input: htmlToString(currSelection),
				type: options.type,
				prompt: options.prompt,
				userId: user.id ?? userIDCookie,
				docId: props?.docID,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/ask-ai/', requestOptions)
			.then((res) => res.json())
			.then((result: string) => {
				setStagedContent(result)
				setAskAIGenerating(false)
			})
			.catch((e) => {
				setAskAIGenerating(false)
				setErrorModalOpen(true)
				resetAskAIOptions()
			})
	}

	const askAiDropdownContent =
		askAIPrompt === 'test' ? (
			<div className="flex gap-2 py-3 px-2 items-center w-80">
				<TextareaAutosize
					placeholder="E.g. Make this more professional"
					autoFocus
					onChange={(e) => {
						setPromptInput(e.target.value)
					}}
					minRows={1}
					maxRows={4}
					value={promptInput}
					className="px-3 py-2 border border-gray-500 rounded outline-none resize-none grow focus:border-gray-900"
				/>
				{FaCircleArrowUp({
					className: 'w-8 text-xl cursor-pointer',
					onClick: (e: any) => handleAskAIDropdownButtonClick(e, { prompt: promptInput }),
				})}
			</div>
		) : (
			<>
				{Object.entries(ASK_AI_TYPES).map(([key, value]) => {
					return (
						<div
							className="ask-ai-dropdown-item"
							key={key + value}
							onClick={(e) => handleAskAIDropdownButtonClick(e, { type: value })}
						>
							<div className="ask-ai-dropdown-item-value fancy-font">{value}</div>
							{/* @ts-ignore Can fix this type error when we port constants.js to constants.ts*/}
							<div className="ask-ai-dropdown-item-desc">{ASK_AI_DESCRIPTIONS[value]}</div>
						</div>
					)
				})}
			</>
		)

	const renderAskAIBubbleMenu = () => {
		if (!editor) return null
		return (
			<>
				<span className="fancy-font askAIResultDropdownTitle whitespace-nowrap overflow-hidden overflow-ellipsis">
					{askAIType}
				</span>
				<div className="askAIResultDropdownOldText">
					{currSelection.length > 250 ? currSelection.slice(0, 200) + '...' : currSelection}
				</div>
				{askAIGenerating ? (
					<div className="topicDivider oscillating-bar"></div>
				) : (
					<div className="topicDivider dividingBorder"></div>
				)}
				<div className="askAIResultDropdownNewText">
					{!askAIGenerating && <Diff oldStr={currSelection} newStr={stagedContent} />}
				</div>

				<div className="askAIResultDropdownButtonContainer">
					<Button
						className="acceptAskAIChangesButton"
						type="tertiary"
						disabled={askAIGenerating}
						onClick={(e) => {
							if (askAIGenerating) return
							try {
								//replace selection with staged contents
								let startSelection = editor.state.selection.from
								editor.commands.deleteSelection()
								editor.commands.insertContentAt(startSelection, completionToHTMLString(stagedContent))
								resetAskAIOptions()
							} catch (e) {
								console.error(e)
							}
						}}
					>
						<span>Accept Changes</span>
					</Button>
					<Button
						className="cancelAskAIChangesButton ml-3"
						type="primary"
						disabled={askAIGenerating}
						onClick={(e) => {
							if (askAIGenerating) return
							resetAskAIOptions()
						}}
					>
						<span>Cancel</span>
					</Button>
				</div>
			</>
		)
	}

	return (
		<>
			<ErrorModal open={errorModalOpen} closeModal={() => setErrorModalOpen(false)} />
			<div>
				<div>
					{editor &&
						documentState &&
						!documentState.isGenerating &&
						!documentState.isTypingAddMore &&
						!documentState.isTypingEssay && (
							<div>
								<BubbleMenu
									editor={editor}
									tippyOptions={{ duration: 100 }}
									shouldShow={({ editor }) => {
										return !askAIBubbleMenuOpen &&
											editor.isEditable &&
											editor &&
											editor.state &&
											editor.state.selection &&
											editor.state.selection.content()
											? editor.state.selection.content().size > 0
											: false
									}}
								>
									{renderStyleButtons()}
								</BubbleMenu>
								<ControlledBubbleMenu
									editor={editor}
									open={askAIBubbleMenuOpen}
									children={renderAskAIBubbleMenu}
									onClickAway={() => {
										resetAskAIOptions()
									}}
								/>
							</div>
						)}

					{editor &&
						documentState &&
						!documentState.isGenerating &&
						!documentState.isTypingAddMore &&
						!documentState.isTypingEssay && (
							<DragHandle
								pluginKey="ContentItemMenu"
								editor={editor}
								onNodeChange={data.handleNodeChange}
								className={`relative pb-4 h-full text-xl ${
									documentViewV2 === 'test' ? 'left-6 pt-0.5' : 'left-3 pt-1'
								}`}
							>
								<div
									className={`${
										documentViewV2 === 'test' ? 'p-1' : 'p-2'
									} rounded hover:bg-gray-100 cursor-pointer text-gray-700 hover:text-gray-900 md:block hidden`}
									onClick={() => {
										posthog.capture('insert-more-plus-clicked')
										actions.handleAdd()
									}}
								>
									{LuPlus({})}
								</div>
							</DragHandle>
						)}
					{props?.docID && (
						<Autocomplete
							editor={editor}
							docID={props.docID}
							showAutocompleteMenu={showAutocompleteMenu}
							setShowAutocompleteMenu={setShowAutocompleteMenu}
						/>
					)}
				</div>
				{documentState && !documentState.isGenerating && (
					<EditorContent id="tiptap-editor" editor={editor} className={documentViewV2 === 'test' ? 'v2' : ''} />
				)}
				{documentState && documentState.setup && props && editor && (
					<AddMore type={props.type} docID={props.docID} isFixed={false} editor={editor} />
				)}
			</div>
		</>
	)
}

export default Document
