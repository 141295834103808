import { useRef, useState } from 'react'
import Button from '../Buttons'
import { FaChevronDown } from 'react-icons/fa6'
import Dropdown from '../Dropdown/Dropdown'
import { DocumentState, REF_STYLES } from '../../redux/types'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import { useDispatch, useSelector } from 'react-redux'

const ReferencesDropdown = ({ docID, includeNull = true }: { docID: string; includeNull?: boolean }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const dispatch = useDispatch()
	const buttonRef = useRef<HTMLButtonElement>(null)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))

	let options = includeNull ? [null, ...Object.keys(REF_STYLES)] : Object.keys(REF_STYLES)

	return (
		<div className="relative">
			<Button
				type="secondary"
				btnRef={buttonRef}
				onClick={() => setDropdownOpen(!dropdownOpen)}
				className="border border-gray-600 px-4"
			>
				<div className="flex items-center gap-3 text-xl">
					{FaChevronDown({ className: 'text-sm' })}
					<div>
						{documentState.userWriterSelections.refStyle
							? REF_STYLES[documentState.userWriterSelections.refStyle as keyof typeof REF_STYLES]
							: 'None'}
					</div>
				</div>
			</Button>
			<Dropdown
				closeButton={buttonRef}
				open={dropdownOpen}
				setOpen={setDropdownOpen}
				body={
					<div className="flex flex-col gap-0.5 max-h-40 overflow-y-scroll">
						{options.map((type, index) => {
							const typeName = type ?? 'None'
							return (
								<div
									key={typeName + index}
									onClick={() => {
										dispatch(
											setUserWriterSelections({
												docID: docID,
												userWriterSelections: {
													refStyle: type,
												},
											})
										)
										setDropdownOpen(false)
									}}
									className={`px-3 py-2 cursor-pointer hover:bg-gray-100 font-semibold text-lg ${
										!type && 'text-gray-700'
									} ${documentState.userWriterSelections.refStyle === type && 'bg-gray-200'}`}
								>
									<span>{type ? REF_STYLES[type as keyof typeof REF_STYLES] : 'None'}</span>
								</div>
							)
						})}
					</div>
				}
				className="absolute top-11 right-0 bg-white border border-gray-300 shadow-md w-36"
			/>
		</div>
	)
}

export default ReferencesDropdown
