import Modal from './Modal'
import './_Rubric.scss'
import { GraderRubric } from '../Navbar/Grader'
import ScoreBar from '../Grader/Scorebar'
import useLoadingDots from '../../hooks/loadingDots'

const RubricModal = ({
	isOpen,
	onClose,
	loading,
	rubric,
	overallScore,
}: {
	isOpen: boolean
	onClose: () => void
	loading: boolean
	rubric: GraderRubric | null
	overallScore?: number
}) => {
	const loadingDots = useLoadingDots(loading)
	const divider = <div className="border-b border-gray-500" />

	const contentAndIdeasScores = [
		{
			title: 'Relevance',
			description:
				'The content score is based on the quality of the ideas and information in your document. You can increase it by adding more relevant information.',
			score: rubric?.relevanceToTopic ?? 50,
		},
		{
			title: 'Depth of Analysis',
			description:
				'The ideas score is based on the quality of the ideas and information in your document. You can increase it by adding more relevant information.',
			score: rubric?.depthOfAnalysis ?? 50,
		},
		{
			title: 'Evidence and Support',
			description:
				'The ideas score is based on the quality of the ideas and information in your document. You can increase it by adding more relevant information.',
			score: rubric?.evidenceAndSupport ?? 50,
		},
		{
			title: 'Consistent Tone',
			description:
				'The content score is based on the quality of the ideas and information in your document. You can increase it by adding more relevant information.',
			score: rubric?.consistencyOfTone ?? 50,
		},
	]

	const grammarAndMechanicsScores = [
		{
			title: 'Grammar',
			description:
				'The content score is based on the quality of the ideas and information in your document. You can increase it by adding more relevant information.',
			score: rubric?.correctGrammar ?? 50,
		},
		{
			title: 'Spelling',
			description:
				'The ideas score is based on the quality of the ideas and information in your document. You can increase it by adding more relevant information.',
			score: rubric?.correctSpelling ?? 50,
		},
		{
			title: 'Word Choice',
			description:
				'The ideas score is based on the quality of the ideas and information in your document. You can increase it by adding more relevant information.',
			score: rubric?.goodWordChoice ?? 50,
		},
		{
			title: 'Paragraph Transitions',
			description:
				'The ideas score is based on the quality of the ideas and information in your document. You can increase it by adding more relevant information.',
			score: rubric?.qualityOfParagraphTransitions ?? 50,
		},
	]

	const ScoreBarLocal = ({ score }: { score: number }) => {
		return (
			<div className="flex gap-1 items-center">
				<ScoreBar score={score} />
				<div className="text-xs">{score}</div>
			</div>
		)
	}

	const overallScoreLocal =
		overallScore ??
		Math.floor(
			(((rubric?.relevanceToTopic ?? 0) +
				(rubric?.depthOfAnalysis ?? 0) +
				(rubric?.evidenceAndSupport ?? 0) +
				(rubric?.consistencyOfTone ?? 0) +
				(rubric?.correctGrammar ?? 0) +
				(rubric?.correctSpelling ?? 0) +
				(rubric?.goodWordChoice ?? 0) +
				(rubric?.qualityOfParagraphTransitions ?? 0)) /
				8) *
				10
		)

	return (
		<Modal open={isOpen} closeModal={onClose} portalClassName="rubric">
			{loading ? (
				<div className="flex justify-center h-48 items-center">
					<div className="text-2xl font-semibold text-gray-700">Grading essay{loadingDots}</div>
				</div>
			) : (
				<div className="flex flex-col gap-4">
					<div className="flex gap-3 justify-between mb-3 items-center">
						<div className="flex flex-col gap-1">
							<div className="text-md font-semibold">Overall score: {overallScoreLocal} out of 100.</div>
							<div className="text-sm text-gray-700">{rubric?.constructiveComments}</div>
						</div>
						<div className="rounded-full flex justify-between items-center border-2 border-primary border-dotted ">
							<div className="text-primary w-20 h-20 text-center flex items-center justify-center font-semibold relative overflow-hidden">
								<div className="colors absolute h-full w-full z-0 rounded-full opacity-80" />
								<span className="text-3xl z-10 text-white">{overallScoreLocal}</span>
								<span className="text-md pt-1 z-10 text-white">%</span>
							</div>
						</div>
					</div>
					<div className="text-xl font-semibold">Content & Ideas</div>
					{divider}
					{contentAndIdeasScores.map((item, index) => (
						<div key={index} className="flex flex-col gap-2">
							<div className="flex gap-3 justify-between items-center">
								<div className="text-sm font-semibold">{item.title}:</div>
								<ScoreBarLocal score={item.score} />
							</div>
						</div>
					))}
					<div className="flex flex-col gap-0.5">
						<div className="text-sm font-semibold">Thesis statement:</div>
						<div className="text-sm text-gray-700">{rubric?.thesisStatement}</div>
					</div>
					<div className="text-xl font-semibold">Grammar & Mechanics</div>
					{divider}
					{grammarAndMechanicsScores.map((item, index) => (
						<div key={index} className="flex flex-col gap-2">
							<div className="flex gap-3 justify-between items-center">
								<div className="text-sm font-semibold">{item.title}:</div>
								<ScoreBarLocal score={item.score} />
							</div>
						</div>
					))}
					<div className="flex flex-col gap-0.5">
						<div className="text-sm font-semibold">Dead words used:</div>
						<div className="text-sm text-gray-700">{rubric?.weakWords}</div>
					</div>
				</div>
			)}
		</Modal>
	)
}

export default RubricModal
